.flexnav {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  vertical-align: middle;
  max-width: 1300px;
  padding-right: 40px;
  background-color: #fb923c;
  margin: auto;
}

.links:hover {
  border-color: whitesmoke;
}

.links {
  margin-left: 30px;
  font-weight: 700;
  font-size: 1.3em;
  color: whitesmoke;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: all 350ms;
}
.linkslogo {
  margin-left: 30px;
  font-weight: 700;
  font-size: 1.3em;
  color: whitesmoke;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: all 350ms;
}

@media screen and (max-width: 600px) {
  .links {
    font-size: 0.8em;
  }
}

.activelink {
  border-bottom: 2px solid;
  border-color: whitesmoke;
}

.navmain {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #fb923c;
  transition: all 500ms ease-in-out;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
}

.navmainmoved {
  transition: all 500ms ease-in-out;
  -webkit-box-shadow: 1px 1px 3px 1px #000000;
  box-shadow: 1px 1px 3px 1px #000000;
}
