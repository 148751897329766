.small-text-my-class-all {
    font-size: 16px !important;
    @media screen and (max-width: 500px) {
        font-size: 10px !important;
    }
    // @media screen and (min-width: 770px) {
    //     font-size: 14px;
    // }
}

.loadingmine::before {
    margin-right: 0.5rem/* 8px */
    ;
    height: 1rem/* 16px */
    ;
    width: 1rem/* 16px */
    ;
    border-radius: 9999px;
    border-width: 2px;
    animation: spin 2s linear infinite;
    content: " ";
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: currentColor;
    border-right-color: currentColor;
}

.small-text-my-class-allauthor {
    font-size: 16px !important;
    // @media screen and (min-width: 770px) {
    //     font-size: 14px;
    // }
}

.small-text-my-class-title-all {
    font-size: 16px !important;
    @media screen and (max-width: 500px) {
        font-size: 10px !important;
    }
    // @media screen and (min-width: 770px) {
    //     font-size: 14px;
    // }
}

.example-enter {
    transform: scale(.9);
}

.example-enter.example-enter-active {
    transform: scale(1);
    transition: all .3s;
}

.example-leave {
    transform: scale(1);
}

.example-leave.example-leave-active {
    transform: scale(0);
    transition: all .3s;
}

.example-exit-active {
    opacity: 0;
    transform: scale(-100%);
    transition: opacity 300ms, transform 300ms;
}