.maincontainer {
    position: relative;
    transition: all 350ms ease-in-out;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid rgba(128, 128, 128, 0.237);
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 220px;
    padding: 10px;
    @media screen and (min-width: 770px) {
        padding: 40px;
    }
}

.mybtn {
    color: white !important;
}