.card-main {
    cursor: pointer;
    background-color: whitesmoke;
    margin-top: 25px;
    margin-bottom: 25px;
    transition: all 350ms;
    &:hover {
        -webkit-box-shadow: 1px 1px 3px 2px #b82525bd;
        box-shadow: 1px 1px 3px 2px #ce2020b3;
        scale: 1.005;
    }
}

$primary:#fb923c;
$secondary:#330572;
$accent:#FFFAFA;
$red:#CF2020;
.title {
    color: $secondary;
}

.left {
    color: $secondary;
}

.dividertest {
    display: flex;
    &:after {
        content: '';
        flex-grow: 1;
        height: 1px;
        background: #fb923c49;
        width: 50%;
        margin-right: 50px;
        margin-left: 50px;
    }
}

.divider-mine {
    position: relative;
    &:before {
        position: absolute;
        display: block;
        content: '';
        border-top: 80px solid #fb923c49;
        width: 1px;
        margin-left: 200px;
        left: 90px;
        margin-top: 80px;
        height: 1px;
        width: 2px;
        @media screen and (min-width: 770px) {
            margin-left: 180px;
        }
        @media screen and (max-width: 770px) {
            border-color: transparent;
        }
    }
}

.small-text-my-class {
    @media screen and (max-width: 770px) {
        font-size: 11px;
    }
    @media screen and (min-width: 770px) {
        font-size: 11px;
    }
}

.small-text-my-class-title {
    @media screen and (max-width: 770px) {
        font-size: 9px;
    }
    @media screen and (min-width: 770px) {
        font-size: 10px;
    }
}