@tailwind base;
@tailwind components;
@tailwind utilities;
$primary:#fb923c;
$secondary:#330572;
$accent:#FFFAFA;
$red:#CF2020;
body {
    margin: 0;
    padding: 0;
    font-family: 'Noto Kufi Arabic', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: 'Noto Kufi Arabic', sans-serif;
}


/* .maincontainer {
    transition: all 350ms ease-in-out;
    min-height: 100vh;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 150px;
    border: 1px solid rgba(128, 128, 128, 0.237);
    border-radius: .5rem;
    display: flex;
} */

.maincontainer {
    transition: all 350ms ease-in-out;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 150px;
    border: 1px solid rgba(128, 128, 128, 0.237);
    border-radius: .5rem;
    padding: 40px;
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}