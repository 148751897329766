.maincontainer {
    transition: all 350ms ease-in-out;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid rgba(128, 128, 128, 0.237);
    border-radius: .5rem;
    padding: 40px;
    display: grid;
    // flex-shrink: 0;
    // flex-wrap: wrap;
    gap: 10px;
    grid-auto-flow: dense;
    grid-template-columns: 1;
    grid-row: 4;
    justify-content: center;
    align-items: center;
    overflow: auto;
    @media screen and (min-width: 770px) {
        grid-template-columns: 50% 50%;
    }
    @media screen and (min-width: 1280px) {
        grid-template-columns: 30% 30% 30%;
    }
}

.card {
    display: flex;
    color: #330572;
    flex-direction: column;
    width: 350px;
    height: 220px;
    border: 2px solid rgba(0, 0, 0, 0.079);
    border-radius: .5rem;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 1px 1px 2px 0px #000000;
    box-shadow: 1px 1px 2px 0px #000000;
    font-weight: 500;
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: all 700ms;
    cursor: pointer;
}

.card:hover {
    text-decoration-color: #fb923c;
    transition: all 700ms;
    color: #fb923c;
    -webkit-box-shadow: 1px 1px 6px 0px #000000;
    box-shadow: 1px 1px 6px 0px #000000;
    .svgtester {
        fill: #fb923c;
        width: 50px;
        height: 50px;
        stroke: #fb923c;
    }
}

.svgtester {
    transition: all 700ms;
    width: 50px;
    height: 50px;
    fill: #330572;
}

.title {
    color: #330572;
    text-align: right;
    line-height: 100px;
    max-width: 1300px;
    text-decoration: underline;
    padding-right: 30px;
    font-size: 25px;
}

.hero {
    height: 500px;
    max-width: 2000px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 90px;
    background-repeat: no-repeat;
    background-image: url(https://cdn.pixabay.com/photo/2021/05/29/18/59/petra-6294051_1280.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    grid-auto-flow: column;
    @media screen and (min-width: 520px) {
        display: grid;
        grid-template-columns: 50% 50%;
    }
    @media screen and (min-width: 800px) {
        display: grid;
        grid-template-columns: 50% 50%;
    }
    @media screen and (min-width: 1500px) {
        display: grid;
        grid-template-columns: 50% 50%;
    }
}

.maintitle {
    text-align: right;
    font-size: 3em;
    font-weight: 700;
    color: whitesmoke;
    padding: 0px 40px;
    @media screen and (max-width: 600px) {
        text-align: center;
        padding: 0px 20px;
    }
}

.secondtitle {
    text-align: center;
    font-size: 1.5em;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.65);
    padding: 0px 40px;
    @media screen and (max-width: 600px) {
        padding: 0px 20px;
    }
}

.wrap {
    grid-area: 3/2;
    grid-row: 2;
    @media screen and (min-width: 800px) {
        align-self: start;
        justify-self: start;
    }
}